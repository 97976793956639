@import "../../../styles/variables";

.preview {
  padding: 1rem 0;

  .profile {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
    margin: 0 auto;
    max-width: 480px;

    .avatar {
      img {
        display: block;
        margin: 0 auto;
        max-width: 120px;
        width: 100%;
      }
    }

    .info {
      span {
        display: block;
        margin: 0 0 .5rem .25rem;
        font-weight: 700;
      }
      p {
        font-size: .9rem;
        margin: 0 0 .5rem .25rem;
      }
    }

    .additionalInfo {
      margin-top: 1rem;
      font-size: .8rem;

      p {
        margin: 0 0 .25rem 0;
      }
    }
  }

  .review {
    display: grid;
    grid-template-columns: auto;
    margin: 2rem auto 0 auto;
    max-width: 400px;

    h4 {
      margin: 0 0 .5rem 0;
    }

    p {
      font-size: .9rem;
      line-height: 1.5rem;
      margin: 0 0 .5rem 0;
      overflow: auto;
    }

    button {
      margin: 2rem auto 0 auto;
      width: 220px;
      padding: 1rem 0;
    }
  }
}
