@import "../../styles/variables";

.select {
  background: rgba(255, 255, 255, 0.9) url('/images/ui/dropdown.svg') no-repeat 100% center;
  border: none;
  border-bottom: 1px solid $aroma-dark;
  border-radius: 0;
  color: #203340;
  cursor: pointer;
  font-family: arial, helvetica, sans-serif;
  font-size: 0.9375em;
  font-style: normal;
  font-weight: normal;
  height: auto;
  max-width: 340px;
  overflow: hidden;
  outline: none;
  padding: 1rem 4rem 1rem 0;
  -webkit-appearance: none;
}
