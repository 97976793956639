@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.modal {
  position: fixed;
  background: $aroma-dark;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;

  @include breakpoint(medium) {
    background: rgba(0,0,0, .4);
  }

  .modalContainer {
    background: $aroma-dark;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;

    @include breakpoint(medium) {
      @include center;
      padding: 4rem 6rem;
      width: 50vw;
      height: auto;
    }
  }

  .modalContent {
    margin: 0 auto;
    position: relative;
    padding: 4rem 0;
    width: 90%;

    @include breakpoint(medium) {
      width: 100%;
      padding: 0;
      overflow: auto;
      max-height: 640px;
    }
  }

  .modalClose {
    position: absolute;
    top: 25px;
    right: 25px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
