@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

$header-height: 175px;
$navbar-height: 80px;

.splitView {
  display: grid;
  grid-template-columns: auto;
  padding-top: $navbar-height;

  @include breakpoint(medium) {
    grid-template-columns: auto 1fr;
    padding-top: 0;
  }

  .container {
    background: $white;
    height: auto;
    min-height: 100vh;
    padding: 0 1rem;

    @include breakpoint(medium) {
      padding: 0 3rem;
      height: auto;
    }

    @include breakpoint(large) {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .header {
    padding: 1rem 0;
    h1 {
      font-size: 1.25rem;
      @include breakpoint(medium) {
        font-size: 2rem;
      }
    }
  }

  .contentContainer {
    height: auto;
    overflow: auto;
    position: relative;
    @include breakpoint(medium) {
      height: calc(100vh - #{$header-height});
    }
  }

  .content {
    padding: 1rem 0;

    p {
      margin-top: 0;
    }
  }

  .centeredContent {
    padding: 6rem 0;
    width: 100%;

    img {
      display: block;
      margin: 0 auto 3rem auto;
      max-width: 70%;
    }

    select {
      display: block;
      margin: 0 auto 3rem auto;
    }

    small {
      text-align: center;
      display: block;
      margin-top: 2rem;

      @include breakpoint(medium) {
        margin-top: 8rem;
      }
    }

    a {
      color: $orange;
    }

    .noResults {
      line-height: 1.5rem;
      max-width: 480px;
      margin: 2rem auto;
      text-align: center;
      strong {
        font-weight: 700;
      }
    }
  }
}
