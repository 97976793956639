@import "../../styles/variables";

.numRating {
  display: grid;
  grid-template-columns: 60px 60px 60px 60px 60px;

  .numInput {
    margin-right: 15px;
    width: auto !important;
  }

  .inputLabel {
    color: $grey;
    display: block;
    font-size: .8rem;
    font-weight: 300;
    padding-left: .125rem;
  }
}
