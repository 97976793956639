@mixin fontSmoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

@mixin fontFamily($font, $style: normal, $weight: normal) {
  font-family: $font, helvetica, arial, sans-serif;
  font-weight: $weight;
  font-style: $style;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin iosZoomFix {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
}
