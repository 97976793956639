@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.navBar {
  background: $aroma;
  display: block;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $nav-bar-zindex;

  @include breakpoint(medium) {
    display: none;
  }

  .branding {
    @include center;
    cursor: pointer;
  }

  .menu {
    @include center-vertically;
    cursor: pointer;
    left: 15px;
    width: 25px;

    @include breakpoint(medium) {
      left: 30px;
    }
  }
}
