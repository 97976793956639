.starRating {
  list-style: none;
  margin: .5rem 0;
  padding: 0;

  &.disabled {
    pointer-events: none;
  }

  li {
    i {
      display: inline-block;
    }
  }

  .star {
    background: url("/images/ui/star-empty.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &.selected {
      background: url("/images/ui/star.svg") no-repeat;
      background-size: cover;
    }
  }
}
