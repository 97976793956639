@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../../styles/breakpoints";

.rating {
  border: none;
  margin: 0 2px;
  padding: 0;

  legend {
    color: $grey;
    font-weight: 700;
  }

  p {
    color: $grey;
    font-weight: 300;
    line-height: 1.5rem;
  }

  form {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 60px;
    position: relative;

    @include breakpoint(xlarge){
      grid-template-columns: auto auto;
    }

    .formRow {
      margin: 2rem 0;
      position: relative;
      min-height: 72px;

      em {
        color: $grey;
        display: block;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      input, textarea {
        border: 1px solid lighten($light-charcoal, 60%);
        color: $grey;
        padding: .25rem 0 .25rem .75rem;
        outline: none;
        line-height: 1.8rem;
        width: calc(100% - .75rem);
      }

      textarea {
        max-height: 240px;
      }
    }
  }
}
