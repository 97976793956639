@import "../../styles/variables";
@import "../../styles/breakpoints";

.footer {
  color: $white;
  font-size: .9rem;
  text-align: center;

  &.docked {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;

    @include breakpoint(medium) {
      position: absolute;
    }
  }

  a {
    color: $white;
    margin-right: 1rem;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    font-size: .7rem;
    margin-top: 2rem;
    color: #959595;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
