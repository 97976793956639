@import "../../styles/variables";
$transition-duration: 100ms;

%baseStyle {
  border: none;
  color: $white;
  cursor: pointer;
  font-size: .9rem;
  font-weight: 600;
  padding: 1rem 5rem;
  outline: none;
}

.primary {
  @extend %baseStyle;
  transition: background-color $transition-duration ease-in-out, color $transition-duration linear;
  background: $aroma-dark;

  &:hover {
    background-color: darken($aroma-dark, 4%);
    color: $orange;
  }

  &.centered {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  &:disabled {
    opacity: .3;
    pointer-events: none;
  }
}

.secondary {
  @extend %baseStyle;
  transition: background-color $transition-duration ease-in-out, color $transition-duration linear;
  background: $light-charcoal;

  &:hover {
    background-color: darken($aroma-dark, 4%);
    color: $orange;
  }

  &.centered {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
