@import "../../styles/variables";
@import "../../styles/breakpoints";

%list-item {
  text-align: left;
  color: lighten($light-charcoal, 20%);
  font-size: .8rem;
}

.reviewToolBar {
  width: 100%;
  border-bottom: 1px solid lighten($light-charcoal, 60%);

  .options {
    list-style: none;
    margin: 0;
    padding: 0 0 1.25rem 0;
    display: grid;
    grid-template-columns: auto 1fr;

    .filters {
      @extend %list-item;
      text-align: right;

      .filterContainer {
        padding: 0 0 0 1rem;
        position: relative;
      }

      .filterInput {
        color: lighten($light-charcoal, 20%);
        border: 1px solid lighten($light-charcoal, 60%);
        font-size: .8rem !important;
        margin-left: .5rem;
        padding: .5rem 0 .5rem .5rem;
        outline: none;
        line-height: .8rem;
        &::placeholder {
          color: lighten($light-charcoal, 40%);
        }
      }
    }

    .records {
      @extend %list-item;
      align-self: center;
      justify-self: start;
    }
  }

  .tooltip {
    position: absolute;
    top: -55px;
    background: #efefef;
    border-radius: 6px;
    padding: 1rem;
    text-align: center;
    right: 0;
    font-size: .8rem;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #efefef;
      position: absolute;
      bottom: -6px;
      left: 90px;
    }
  }

  .toolTipDisabled {
    display: none;
  }
}
