@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

$max-nav-height: 151px;
$max-footer-height: 151px;

.nav {
  background: $aroma;
  display: none;
  height: 100vh;

  @include breakpoint(medium) {
    display: block;
  }

  header {
    padding: 3rem 2rem;
    max-height: $max-nav-height;

    img {
      max-width: 130px;
    }

    small {
      display: block;
      color: $orange;
    }
  }

  ul {
    background: $aroma-dark;
    list-style: none;
    margin: 0;
    padding: 3rem 0;
    height: calc(100vh - #{$max-nav-height} - #{$max-footer-height} - 6rem);
    overflow: auto;

    a {
      background: $aroma-dark;
      color: $white;
      display: block;
      font-size: .75rem;
      letter-spacing: .07rem;
      margin-bottom: .5rem;
      padding: 1rem 3rem 1.5rem 3rem;
      text-decoration: none;
      text-transform: uppercase;
      opacity: .25;

      &.active {
        background: darken($aroma-dark, 2%);
        opacity: 1;
      }

      &:hover {
        background: darken($aroma-dark, 2%);
        opacity: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }

      li {
        display: block;
        padding: 0;

        span {
          margin-left: 1.25rem;
        }

        img {
          top: 7px;
          position: relative;
        }
      }
    }
  }

  footer {
    color: $white;
    max-height: $max-footer-height;
    padding: 3rem 2rem;
  }
}
