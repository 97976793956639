.completed {
  display: grid;
  grid-template-columns: auto;
  padding: 1rem 0;
  margin: 0 auto;
  max-width: 640px;
  text-align: center;

  button {
    margin: 2rem auto 0 auto;
    width: 220px;
    padding: 1rem 0;
  }
}
