@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.reviews {
  .listContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(xlarge) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .list {
      height: auto;
      margin-bottom: 1rem;
      border: 1px solid lighten($light-charcoal, 60%);
      text-align: center;
      padding: 2rem;
      min-height: 250px;

      @include breakpoint(xlarge) {
        margin-right: 2rem;
        margin-bottom: 0;
        &:nth-child(3n+0) {
          margin-right: 0;
        }
      }

      small {
        color: $light-charcoal;
        display: block;
        margin-bottom: 1rem;
      }

      &.advertisement {
        padding: 0;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          @include breakpoint(xlarge) {
            width: auto;
          }
        }
      }
    }
  }
}
