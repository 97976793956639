// https://github.com/LivelyHSA/lively-components/pull/7
// review request to use both variables and breakpoint map
$x-small: 0;
$small: 480px;
$medium: 900px;
$large: 1024px;
$x-large: 1280px;

// Map for iterating through breakpoints
$breakpoints: (
        xs: $x-small,
        small: $small,
        medium: $medium,
        large: $large,
        xlarge: $x-large
);

// Breakpoint offset
// Review req: work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// https://github.com/twbs/bootstrap/blob/master/scss/mixins/_breakpoints.scss#L42
$breakpoint-offset: .02;

/// Mixin for handling various responsive breakpoints will apply @content to the given breakpoint and wider
/// @access public
/// @content The adjustments you want to make at certain break points
/// @param {type} $point - The point value where break should occur
/// @example scss - breakpoint
/// @include breakpoint(large) { your adjustments here }
@mixin breakpoint($breakpoint) {
  // if val in map exists sets break point val from breakpoint map
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-val: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-val) {
      @content;
    }
  }
  @else {
    //  warn if val does not exist in map
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

/// Mixin for handling various responsive breakpoints will apply @content to the given breakpoint, not viewports any wider or narrower
/// @access public
/// @content The adjustments you want to make at certain break points
/// @param {type} $point - The point value where break should occur
/// @example scss - breakpoint-only
/// @include breakpoint-only(large) { your adjustments here }
@mixin breakpoint-only($point) {
  @if $point == small {
    @media (min-width: $small) and (max-width: $medium - $breakpoint-offset) { @content; }
  }
  @else if $point == medium {
    @media (min-width: $medium) and (max-width: $large - $breakpoint-offset) { @content; }
  }
  @else if $point == large {
    @media (min-width: $large) and (max-width: $xlarge - $breakpoint-offset) { @content; }
  }
  @else if $point == $xlarge {
    @media (min-width: $xlarge) and (max-width: $xxlarge - $breakpoint-offset) { @content; }
  }
}
