// Color palette
$white: #FFFFFF;
$off-white: #cccccc;
$orange: #FF8560;
$aroma: #404350;
$aroma-dark: #323540;
$light-charcoal: #464953;
$grey: #676666;
$charcoal: #2F3139;
$gold: #FFCE02;
$light-blue: #4C6FAE;

$off-canvas-zindex: 5;
$nav-bar-zindex: 4;
