@import "../../styles/mixins";
@import "../../styles/variables";

.landing {
  height: 100vh;
  background: url("/images/content/contractor-business.jpg") no-repeat center;
  background-size: cover;
  position: relative;

  h3 {
    text-align: center;
    color: $orange;
    font-weight: 600;
  }
}

.landingContainer {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    margin-top: 10rem;
  }
}