@import "../../styles/variables";

.privacyPolicy {
  h3 {
    color: $white;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: left;
  }

  p {
    color: $white;
    font-size: .9rem;
    line-height: 1.5rem;
    text-align: left;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}
