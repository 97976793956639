@import "../../styles/variables";
@import "../../styles/breakpoints";

.offCanvas {
  background: $aroma-dark;
  position: fixed;
  background: $aroma-dark;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: $off-canvas-zindex;

  @include breakpoint(medium) {
    display: none;
  }

  .branding {
    position: absolute;
    top: 2rem;
    left: 3rem;
  }

  .close {
    position: absolute;
    top: 2.6rem;
    right: 3rem;
    cursor: pointer;
  }

  .menu {
    list-style: none;
    margin: 10rem 0 0 0;
    padding: 0;

    a {
      background: $aroma-dark;
      color: $white;
      display: block;
      font-size: .75rem;
      letter-spacing: .07rem;
      margin-bottom: .5rem;
      padding: 1rem 3rem 1.5rem 3rem;
      text-decoration: none;
      text-transform: uppercase;
      opacity: .25;

      &.active {
        background: darken($aroma-dark, 2%);
        opacity: 1;
      }

      &:hover {
        background: darken($aroma-dark, 2%);
        opacity: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }

      li {
        display: block;
        padding: 0;

        span {
          margin-left: 1.25rem;
        }

        img {
          top: 7px;
          position: relative;
        }
      }
    }
  }
}
