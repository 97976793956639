@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

$tool-tip-size: 14px;

.toolTip {
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  text-align: center;
  user-select: none;

  .container {
    position: relative;
  }

  .indicator {
    border: 1px solid $off-white;
    border-radius: 50%;
    color: $off-white;
    display: inline-block;
    font-size: $tool-tip-size - 2px;
    font-style: normal;
    height: $tool-tip-size;
    line-height: $tool-tip-size;
    padding: .125rem;
    position: relative;
    top: -2px;
    width: $tool-tip-size;
  }

  .tip {
    background: rgba($aroma, .95);
    display: none;
    font-size: .8rem;
    line-height: 1.1rem;
    padding: .75rem;
    position: absolute;
    width: 250px;
    color: $white;
    box-shadow: rgba(0, 0, 0, .4) 0 0 12px 0;

    @include breakpoint(xlarge){
      transform: translateX(-125px);
    }


    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba($aroma, .95);
      left: 6px;

      @include breakpoint(xlarge){
        bottom: -5px;
        left: 50%;
        transform: translateX(-6px);
      }
    }
  }

  &:hover {
    .tip {
      display: block;
    }
  }
}
