@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import "styles/variables";
@import "styles/mixins";
@import "../node_modules/normalize.css";

body {
  @include fontSmoothing;
  background: $aroma-dark;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  width: 100%;
  height: 100%;
}

.app {
  width: 100vw;
  height: 100vh;
}

@include iosZoomFix;
