@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";


.detailsContainer {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 30px;
  margin-bottom: 2.5rem;

  @include breakpoint(medium){
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
}

.location {
  padding: 3rem 3rem 4rem;
  background: #fafafa;
  border: 1px solid #e3e4e8;
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
  }

  dl {
    margin: 0;

    dd {
      color: $aroma;
      font-size: .9rem;
      margin: 0;
      padding: 1rem 0 0;
    }

    h2 {
      margin: 0;
    }
  }
}

.averageRating {
  padding: 3rem 3rem 4rem;
  background: #fafafa;
  border: 1px solid #e3e4e8;
  text-align: center;

  p {
    padding: 0;
    margin: 1rem !important;
  }

  h2 {
    margin: 0;
  }

  strong {
    font-weight: normal;
    display: block;
    margin: 1rem 0;
    font-size: 1.5rem;
  }

  small {
    margin: 1rem;
  }
}

.carousel {
  padding: 3rem 0 5rem 0;
  display: grid;
  grid-template-columns: auto;
  background: #fafafa;
  border: 1px solid #e3e4e8;
  overflow: hidden;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
}
