@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/breakpoints";

.carousel {
  position: relative;

  .container {
    max-width: 400px;
    margin: 0 auto;
    background: #fafafa;
  }

  .slide {
    text-align: center;
    outline: none;
  }

  p {
    text-align: center;
  }
}
