@import "../../styles/variables";
@import "../../styles/breakpoints";

%list-item {
  display: inline-block;
  font-size: .8rem;
  text-align: left;
  color: lighten($light-charcoal, 20%);
  text-decoration: none;
  position: relative;

  @include breakpoint(medium) {
    font-size: .9rem;
  }

  @include breakpoint(large) {
    font-size: 1rem;
  }

  &.active {
    &:after {
      content: ' ';
      width: 80%;
      background: $light-blue;
      height: 3px;
      position: absolute;
      bottom: -1px;
    }
  }

  &:hover {
    &:after {
      content: ' ';
      width: 80%;
      background: $light-blue;
      height: 3px;
      position: absolute;
      bottom: -1px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  li {
    padding: 1rem 1rem 1rem 0;
  }
}

.ratingSteps {
  display: grid;
  grid-template-columns: auto;
  width: 100%;
  border-bottom: 1px solid lighten($light-charcoal, 60%);

  .steps {
    list-style: none;
    margin: .25rem 0 0 0;
    padding: 0;

    a {
      @extend %list-item;
      margin-right: .5rem;

      @include breakpoint(large) {
        margin-right: 3rem;
      }
    }
  }
}
