@import "../../styles/variables";

.splitView {
  display: grid;
  grid-template-columns: auto 1fr;

  .content {
    background: $white;
    padding: 3rem 4rem;
  }
}
