@import "../../styles/variables";

.termsOfService {
  h3 {
    color: $white;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: left;
  }

  a {
    color: $white;
  }

  dl {
    color: $white;
    font-size: .9rem;
    line-height: 1.5rem;
    text-align: left;

    dt {
      font-size: 1rem;
      font-weight: 700;
      margin: 1rem 0 0 0;
      padding: 0;
    }

    dd {
      margin: 0;
    }
  }
}
